<template>
  <div class="tw-location-picker-element md:max-w-[280px]">
    <div :class="{ 'pointer-events-none': isEditorMode }">
      <WebLocationPicker
        v-model="selectedLocation"
        :type="config.type"
        :name="translate('generate.templates.questions.locationPicker.name')"
        :placeholder="translate('generate.templates.questions.locationPicker.placeholder', locale)"
        :page-options="pageOptions"
        :label="labelText"
        :label-style="labelStyle"
      />
    </div>
    <div v-if="$slots.default" class="tw-location-picker-element__bottom">
      <div
        class="tw-slot-wrapper"
        :class="{ 'tw-slot-wrapper--disabled': !canSubmit && !isEditorMode }"
        @[btnClick]="finish"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import WebLocationPicker from '@shared/components/async-select/index.vue';
import { type PropType, ref, computed, toRefs, onBeforeMount, type Ref } from 'vue';
import { globalEmit } from '@shared/utils/helpers';
import type { Element as ElementType, PageOptions } from '../../types/model';
import type { ElementOptions } from '../../types/options';
import { useTranslate } from '@shared/composable/useTranslate';
import { useLabelOptions } from '@shared/composable/useLabelOptions';
import { type QuizAnswer, getPreviousAnswerByQuestionType } from '@shared/utils/getPreviousAnswerByQuestionType';

const { translate } = useTranslate();

const selectedLocation:Ref = ref(null);
const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));

const props = defineProps({
  config: { type: Object as PropType<ElementOptions['locationpicker']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  locale: { type: String, default: 'en' },
  element: { type: Object as PropType<ElementType<'locationpicker'>>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) }
});

const { element, pageOptions } = toRefs(props);
const { labelStyle, labelText } = useLabelOptions({ element, pageOptions });

const canSubmit = computed(() => {
  if (props.config.required) {
    return !!selectedLocation.value;
  }
  return true;
});

function finish(event?: any) {
  if (!canSubmit.value) return;
  globalEmit('nextQuestion', [event, JSON.stringify(selectedLocation.value)]);
}

function setPreviousAnswer() {
  if (props.pageData?.answers?.length) {
    selectedLocation.value = getPreviousAnswerByQuestionType({
      answers: props.pageData?.answers as QuizAnswer[],
      questionType: 'locationpicker',
    });
  }
}

onBeforeMount(() => {
  setPreviousAnswer();
})
</script>
